import React from 'react';
import { RegionProvider } from './src/context/RegionContext';
require('./node_modules/bootstrap/dist/css/bootstrap.min.css');
require('./src/styles/global.scss');

export const wrapRootElement = ({ element }) => <RegionProvider>{element}</RegionProvider>;

export const onRouteUpdate = ({ location }) => {
  const prevRegion = window.localStorage.getItem('region');
  const region = window.___region || prevRegion || 'UK';

  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
  const hasAcceptedCookies = cookies.some((cookie) => cookie.startsWith('acceptCookies='));

  if (region && hasAcceptedCookies) {
    console.log(`Set ${region} for ${location.pathname}`);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'POMO_CHANGE_ROUTE',
      region: region,
      page_path: location.pathname,
    });

    // gtag('event', 'POMO_CHANGE_ROUTE', { Region: region });

    // window.gtag('event', 'page_view', {
    //   page_path: location.pathname,
    //   region: region,
    // });

    // window.gtag('event', 'POMO_CHANGE_ROUTE', {
    //   page_path: location.pathname,
    //   region: region,
    // });
    // window.gtag('event', 'page_view', {
    //   page_path: location.pathname,
    //   [REGION_DIMENSION]: region,
    // });
  }
};
